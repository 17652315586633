/* global Component*/
class VariantsFilterComponent extends Component {

    static name() {
        return "variantsFilter";
    }

    static componentName() {
        return "variantsFilter";
    }

    getProps() {
        return ['filterField'];
    }

    data() {
        return {
            display:true,
            isMobile: $(window).width() <= 560,
            renderView:false,
            collections:[],
        };
    }

    getWatch() {
        return {
            '$route': 'generateFilter',
            '$store.state.itemTimeFlag':'generateFilter',
            '$store.state.itemUpdate': 'calculateResults'
        };
    }

    mounted() {
        return async function () {
            let self = this;
            this.fetchData = this.fetchData.bind(this);
            this.subscribeEvent("variants-select",this.fetchData);
            this.display = !this.isMobile;
            $(window).resize(function () {
                self.isMobile = $(window).width() <= 560;
                self.display = !self.isMobile;
            });
        };
    }

    getMethods() {
        return {
            loadVariantData:this.loadVariantData,
            generateFilter:this.generateFilter,
            resetCountFilters:this.resetCountFilters,
            calculateResults:this.calculateResults,
            launchFilter:this.launchFilter,
            fetchData: this.fetchData,
        };
    }

    loadVariantData() {
        const variantsData = {};
        if(!this.filterField.FilterCollection)
            return variantsData;
        for(let item of this.$store.state.items_store.values()) {
            if(item.VariantionData) {
                for(let variant of item.VariantionData) {
                    for(let concept of variant.Variants) {
                        if(concept && concept.Concept === this.filterField.FilterCollection)
                            variantsData[concept.Code] = concept.Comment;
                    }
                }
            }
        }
        return variantsData;
    }

    async generateFilter() {
        let collection = new Map();
        const variantsData = this.loadVariantData();
        for (let variantCode of Object.keys(variantsData)) {
            if (!collection.has(variantCode)) {
                let objectFilter = {};
                objectFilter.name = 'valueFilter' + variantCode;
                objectFilter.type = 'valueFilter';
                objectFilter.label = variantsData[variantCode];
                objectFilter.value = variantsData[variantCode];
                objectFilter.code = variantCode;
                objectFilter.filterField = "varieties_children";
                objectFilter.itemCount = 1;
                objectFilter.hiddens = new Map();
                objectFilter.condition = `x.VariantionData.filter(y=>y.Variants.filter(v=>v && v.Code === "${variantCode}").length>0).length>0`;
                objectFilter.result = true;
                objectFilter.active = false;
                collection.set(variantCode, objectFilter);
            }
        }
        this.collections = Array.from(collection.values());
        this.calculateResults();
    }

    resetCountFilters(){
        for(let filter of this.collections) {
            filter.itemCount = 0;
        }
    }

    calculateResults(){
        let items =  this.$store.getters.getItemsVisibles;//this.$store.getters.getItems
        this.resetCountFilters();
        let global = 0;
        const tempCollections =  Array.from(this.collections);
        for(let x of items.filter((e)=>e.visible)) {
            if(x.VariantionData)
                for (let filter of tempCollections) {
                    try{
                        let isVisible = eval(filter.condition);
                        if (isVisible) {
                            filter.itemCount = filter.itemCount + 1;
                            filter.active = this.$store.state.filters.indexOf(filter.name) !== -1;
                            global = global + 1;
                        }
                    } catch (err) {
                        continue;
                    }
                }
        }
        this.collections = Array.from(tempCollections);
        this.display = this.collections.filter((e)=>e.itemCount > 0).length > 0;
    }

    launchFilter(filters){
        filters.active = true & filters.active;
        this.$store.dispatch('addFilter',filters);
    }

    fetchData() {
        this.renderView = !this.renderView;
        this.calculateResults();
    }

    getTemplate() {
        return `<div class="filtervariantFilter" >
                   <div>
                        <p class="filter-title" @click="display = !display">
                            {{capitalize(tr(filterField.FilterCollection))}}
                            <span v-show="display" class="right"><i class="icon fas fa-minus"></i></span>
                            <span v-show="!display" class="right"><i class="icon fas fa-plus"></i></span>
                        </p>
                    </div>
                    <section class="filter-options" :class="{'visible-filter': display}" @click="display = !isMobile">
                        <ul>
                          <template v-for="filterClass of collections">
                                <li :key="'li'+filterClass.name" v-if="filterClass.itemCount>0">
                                    <div class="custom-control custom-checkbox">
                                      <input :key="'input'+filterClass.name" class="custom-control-input" type="checkbox" v-model="filterClass.active" :id="filterClass.name" :name="filterClass.name"  @click="launchFilter(filterClass)"/>
                                      <label :for="filterClass.name" class="custom-control-label" >{{filterClass.label}} ({{filterClass.itemCount}})</label>
                                    </div>
                                </li>
                          </template>
                        </ul>
                    </section>
               </div>`;
    }
}

VariantsFilterComponent.registerComponent();